<template>
  <div class="parallax">

  </div>
  <div class="content">
    <img src="../assets/mylogo.png" alt="logo" class="circlelogo"/>
    <p>Welcome to my site</p>
  </div>
  
  <div class="about">
    <h1>About</h1>
    <p>This site will be home to my projects</p>
  </div>

  <div class="parallax2"></div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.parallax{
  background-image: url('../assets/bgimg.jpeg');
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

template {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
}
.circlelogo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
.about {
  background-color: #f2f2f2;
  padding: 0;
  margin-top: 0;
  text-align: center;
}
.parallax2{
  background-image: url('../assets/monster-with-melting-candle.mp4');
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}
</style>
