<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/mylogo.png" /> -->
    <HomePage msg="" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HomePage from "@/components/HomePage.vue"; // @ is an alias to /src

@Options({
  components: {
    HomePage,
  },
})
export default class HomeView extends Vue {}
</script>
